<template>
	<div>
		<div>
			<v-card flat>
				<v-card-title class="tw-flex tw-items-center tw-p-0">
					<span> {{ $t('contract-templates-0') }} </span>
					<v-spacer />

					<v-btn
						v-if="hasChanges"
						text
						color="primary"
						class="tw-mr-3"
						@click="setCurrentEditor(currentTab)"
					>
						{{ $t('discard') }}
					</v-btn>
					<base-button
						:loading="isUpdating"
						:disabled="!hasChanges"
						@click="onSubmit"
					>
						{{ $t('actions.update') }}
					</base-button>
				</v-card-title>
			</v-card>

			<v-tabs v-model="currentTab">
				<v-tab>{{ $t('buyer-template') }}</v-tab>
				<v-tab>{{ $t('supplier-template') }}</v-tab>
			</v-tabs>

			<vue-editor v-model="editor.details" class="tw-mt-1" />
		</div>
	</div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { Contracts } from '../../api'

export default {
	name: 'ContractTemplates',
	components: {
		VueEditor,
	},
	data: () => ({
		currentTab: 0,
		isUpdating: false,
		templates: [],
		editor: {
			details: '',
		},
	}),
	created() {
		this.onFetchTemplates()
	},
	computed: {
		isUpdate() {
			return !!this.editor.id
		},
		refEditor() {
			return this.getTemplate(this.currentTab)
		},
		tabName() {
			return this.getTabName(this.currentTab)
		},
		hasChanges() {
			return this.refEditor?.details !== this.editor.details
		},
	},
	watch: {
		currentTab: {
			immediate: true,
			handler(index) {
				this.setCurrentEditor(index)
			},
		},
	},
	methods: {
		getTabName(index) {
			return index === 0 ? 'buyer' : 'supplier'
		},
		getTemplate(tabIndex) {
			const type = this.getTabName(tabIndex)
			return this.templates.find((template) => {
				return template.type === type
			})
		},
		setCurrentEditor(index = this.currentTab) {
			const template = this.getTemplate(index)
			this.editor = {
				...this.editor,
				...(template || {}),
			}
		},
		onFetchTemplates() {
			Contracts.getDetails().then(([err, res]) => {
				if (err) return
				this.templates = res.contractDetails
				this.setCurrentEditor(this.currentTab)
			})
		},
		onSubmit() {
			const handler = this.editor.id
				? Contracts.updateDetails
				: Contracts.createDetails

			const onSubmit = async () => {
				this.isUpdating = true
				const [err] = await handler({ ...this.editor, type: this.tabName })
				this.isUpdating = false

				if (err) {
					this.$toast.error(
						err.message ||
							this.$t('failed-to', [this.isUpdate ? 'update' : 'create'])
					)
					return
				}
				this.onFetchTemplates()
			}

			if (this.isUpdate) {
				this.$root.confirmation = {
					handler: onSubmit,
					message: this.$t('sure-to-update-template', [this.tabName]),
				}
				return
			}

			onSubmit()
		},
	},
}
</script>
